import { CONSTANTS } from './config';
import HowTo from './modules/howto';

function init() {
  console.info('@app - init');
  initHowToGuide();
}

function initHowToGuide() {
  const howto = document.querySelector(CONSTANTS.howto.dom.base);

  if(!document.body.contains(howto))
    return false;

  new HowTo(howto);
}

console.info('@app');

document.addEventListener("DOMContentLoaded", init);